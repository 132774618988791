import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { IconButton, Button, Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import Slider from '@material-ui/core/Slider';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SettingsIcon from '@material-ui/icons/Settings';

export default function SettingsDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [settings, setSettings] = React.useState(props.initialSettings);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const battleMarks = [
    {
      value: 0,
      label: 'All',
    },
    {
      value: 33,
      label: 'Trainers and Wilds',
    },
    {
      value: 66,
      label: 'Wilds',
    },
    {
      value: 100,
      label: 'None',
    },
  ];


  const handleSwitch = (event) => {
    setSettings({ ...settings, [event.target.name]: event.target.checked });
  };

  const handleRadio = (event) => {
    setSettings({ ...settings, [event.target.name]: event.target.value });
  };

  const handleSpoilBattle = (event, newValue) => {
    setSettings({ ...settings, 'spoilBattle': Number(newValue) });
  };

  const handleCollapseBattle = (event, newValue) => {
    setSettings({ ...settings, 'collapseBattle': Number(newValue) });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.onChange(settings);
    setOpen(false);
  };

  function battleValuetext(value) {
    return battleMarks.findIndex((mark) => mark.value === value).label;
  }

  return (
    <div style={{ position: "fixed", top: '0px', left: '50px' }}>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <SettingsIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth='xs'
        fullWidth={true}
      >
        <DialogTitle id="responsive-dialog-title">{"Settings"}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Colored Text by Character (SOON)
          </DialogContentText>
          <Switch disabled
            checked={settings.coloredText}
            onChange={handleSwitch}
            color="primary"
            name="coloredText"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />

          <DialogContentText style={{ marginTop: '25px' }}>
            OOC Display
          </DialogContentText>
          <RadioGroup row aria-label="ooc-display" name="ooc" onChange={handleRadio} value={settings.ooc}>
            <FormControlLabel value="inline" control={<Radio color="primary" />} label="Inline" />
            <FormControlLabel value="split" control={<Radio color="primary" />} label="Split" />
            <FormControlLabel value="off" control={<Radio color="primary" />} label="Off" />
          </RadioGroup>

          <DialogContentText style={{ marginTop: '25px' }}>
            Whispers (SOON)
          </DialogContentText>
          <RadioGroup row aria-label="whispers-display" name="whispers" onChange={handleRadio} value={settings.whispers}>
            <FormControlLabel value="inline" control={<Radio color="primary" />} label="Inline" disabled />
            <FormControlLabel value="split" control={<Radio color="primary" />} label="Split" disabled />
            <FormControlLabel value="off" control={<Radio color="primary" />} label="Off" disabled />
          </RadioGroup>

          <DialogContentText style={{ marginTop: '25px' }}>
            Pagination (SOON)
          </DialogContentText>
          <RadioGroup row aria-label="pagination" name="pagination" onChange={handleRadio} value={settings.pagination}>
            <FormControlLabel value="off" control={<Radio color="primary" />} label="Off" disabled />
            <FormControlLabel value="on" control={<Radio color="primary" />} label="On" disabled />
          </RadioGroup>

          <DialogContentText style={{ marginTop: '25px' }}>
            Collapse Battles (SOON)
          </DialogContentText>

          <Slider
            disabled
            name="collapseBattle"
            value={settings.collapseBattle}
            getAriaValueText={battleValuetext}
            aria-labelledby="discrete-slider-restrict"
            step={null}
            marks={battleMarks}
            style={{ width: 300 }}
            onChange={handleCollapseBattle}
          />

          <DialogContentText style={{ marginTop: '25px' }}>
            Show Battle Results (SOON)
          </DialogContentText>
          <Slider
            disabled
            name="spoilBattle"
            value={settings.spoilBattle}
            getAriaValueText={battleValuetext}
            aria-labelledby="discrete-slider-restrict"
            step={null}
            marks={battleMarks}
            style={{ width: 300 }}
            onChange={handleSpoilBattle}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
          <Button autoFocus onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}