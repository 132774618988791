import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class PokemonLookupProfile extends Component {
   constructor(props) {
      super(props);

      const urlParams = this.props.match;
      const pokemon = null;
      this.state = { pokemon: pokemon, pokemonId: urlParams.params.id };
   }

   callAPI() {
      fetch("https://api.soaringsomeone.me/kaiyou/lookup/" + this.state.pokemonId)
         .then(res => res.json())
         .then((data) => {

            // Sort by level
            data.movesLevelup = data.movesLevelup.sort((a, b) => {
               return a.level - b.level;
            });

            // Sort alphabetically
            data.movesMachine = data.movesMachine.sort((a, b) => {
               var textA = a.name.toUpperCase();
               var textB = b.name.toUpperCase();
      
               return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            
            this.setState({ pokemon: data });
         });
   }

   componentDidMount() {
      this.callAPI();
   }

   render() {
      if (!this.state.pokemon) {
         return (
            <Container style={{ width: '100%', paddingLeft: '15%', paddingRight: '15%' }} >
               Loading
               </Container>
         );
      } 

      return (
         <Grid container style={{ width: 'calc(100% - 48px)', paddingLeft: '15%', paddingRight: '15%', paddingTop:'50px', paddingBottom:'50px'}} spacing={5}>
            <Grid item lg={4} xs={12}>
               <img src={this.state.pokemon.imageUrl} style={{width:'100%', outline: 'solid'}}></img>
            </Grid>
            <Grid item lg={8} xs={12}>
               <Typography component="div" variant='h3'>
                  {this.state.pokemon.speciesName}
               </Typography>
               <Typography component="div" style={{ paddingBottom: '30px' }} variant='subtitle2'>
                  The {this.state.pokemon.category} Pokemon
               </Typography>
               <Typography component="div">
                  Type: {this.state.pokemon.types[0]} {this.state.pokemon.types.length > 1 ? "/ " + this.state.pokemon.types[1] : ""}
               </Typography>
               <Typography component="div">
                  Basic Abilities: {this.state.pokemon.abilityBasic.join(", ")}
               </Typography>
               <Typography component="div">
                  Advanced Abilities: {this.state.pokemon.abilityAdvanced.join(", ")}
               </Typography>
               <Typography component="div">
                  High Abilities: {this.state.pokemon.abilityHigh.join(", ")}
               </Typography>
               <Typography component="div" variant='subtitle2' style={{ paddingTop: '20px' }}>
                  "{this.state.pokemon.pokedex}"
               </Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
               <Typography component="div" variant="h4" style={{ paddingBottom: '15px', paddingTop: '50px' }}>Level-up moves</Typography>
               {this.state.pokemon.movesLevelup.map(move =>
                  <Typography component="div" key={move.name}>
                     <Grid container>
                        <Grid item lg={1} xs={2}>
                           <MovesetLevel value={move} />
                        </Grid>
                        <Grid item lg={11} xs={10}>
                           <MovesetData value={move} />
                        </Grid>
                     </Grid>
                  </Typography>
               )}
            </Grid>
            <Grid item lg={6} xs={12}>
               <Typography component="div" variant="h4" style={{ paddingBottom: '15px', paddingTop: '50px' }}>TM/HM moves</Typography>
               {this.state.pokemon.movesMachine.map(move =>
                  <Typography component="div" key={move.name}>
                     <Grid container>
                        <Grid item xs={12}>
                           <MovesetData value={move} />
                        </Grid>
                     </Grid>
                  </Typography>
               )}
            </Grid>
         </Grid>
      );
   }
}

function MovesetLevel(props) {
   var value = props.value;
   return (
      <span>
         {value.level}
      </span>
   );
}

function MovesetData(props) {
   var value = props.value;
   return (
      <span>
         {value.name}
      </span>
   );
}

export default PokemonLookupProfile;