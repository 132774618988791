import React, { useEffect } from 'react';
import SessionEvent from '../../SessionEvent/session-event';

export default function Ooc(props) {
   const [event, setEvent] = React.useState(props.sessionEvent);
   const [settings, setSettings] = React.useState(props.initialSettings);
   
   return (
      <SessionEvent sessionEvent={event} settings={settings} key={event.Id} />
   );
}