import React, { Component } from 'react';
// import '../App.css';

class RemoveLater2 extends Component {
   constructor(props) {
      super(props);

      
   console.log(returnNewPokemon());
   }

   render() {
      return (
         <div className="container">
         </div>
      );
   }
}

var levelup = [
   {"level":1, "name":"Scratch"},
{"level":1, "name":"Yawn"},
{"level":4, "name":"Water Gun"},
{"level":7, "name":"Encore"},
{"level":10, "name":"Slack Off"},
{"level":14, "name":"Faint Attack"},
{"level":17, "name":"Aqua Ring"},
{"level":20, "name":"Water Pulse"},
{"level":24, "name":"Slash"},
{"level":27, "name":"Curse"},
{"level":30, "name":"Rest"},
{"level":30, "name":"Snore"},
{"level":34, "name":"Body Slam"},
{"level":37, "name":"Surf"},
{"level":40, "name":"Entrainment"},
{"level":44, "name":"Flail"},
{"level":47, "name":"Amnesia"},
{"level":50, "name":"Hydro Pump"}
]


var tms = [
   {"name":"Hone Claws"},
{"name":"Calm Mind"},
{"name":"Roar"},
{"name":"Toxic"},
{"name":"Hail"},
{"name":"Bulk Up"},
{"name":"Hidden Power"},
{"name":"Taunt"},
{"name":"Ice Beam"},
{"name":"Blizzard"},
{"name":"Protect"},
{"name":"Rain Dance"},
{"name":"Frustration"},
{"name":"Smack Down"},
{"name":"Return"},
{"name":"Dig"},
{"name":"Brick Break"},
{"name":"Double Team"},
{"name":"Rock Tomb"},
{"name":"Aerial Ace"},
{"name":"Facade"},
{"name":"Rest"},
{"name":"Attract"},
{"name":"Low Sweep"},
{"name":"Scald"},
{"name":"Fling"},
{"name":"Endure"},
{"name":"Rock Smash"},
{"name":"Shadow Claw"},
{"name":"Gyro Ball"},
{"name":"Psych Up"},
{"name":"X-Scissor"},
{"name":"Swagger"},
{"name":"Substitute"},
{"name":"Focus Punch"},
{"name":"Sleep Talk"},
{"name":"Water Pulse"},
{"name":"Cut"},
{"name":"Surf"},
{"name":"Strength"},
{"name":"Dive"},
{"name":"Waterfall"}
]


function returnNewPokemon() {
   var pokemon = {
      "speciesName": 'Chilloth',
      "category": 'Slow Floating',
      "pokedex": "Chilloth floats calmly in the water by connecting its arms to make an inner tube. It lets swimmers hold on when they need to catch their breath.",
      "imageUrl": "https://static.wikia.nocookie.net/capx/images/2/24/ChillBro.png",
      "region": "Urobos",
      "types": [
         'water'
      ],
      "abilityBasic": [
         "Content", "Oblivious"
      ],
      "abilityAdvanced": [
         "Gluttony", "Swift Swim"
      ],
      "abilityHigh": [
         "Deep Sleep"
      ],
      "movesLevelup": levelup,
      "movesMachine": tms
   };

   return pokemon;
}

export default RemoveLater2;