import React, { Component } from 'react';
// import '../App.css';

class RemoveLater extends Component {
   constructor(props) {
      super(props);

      listNames.forEach(x => {
         pokeApi(x);
      })
   }

   render() {
      return (
         <div className="container">
         </div>
      );
   }
}

function pokeApi(pokemonName) {
   fetch('https://pokeapi.co/api/v2/pokemon/' + pokemonName.toLowerCase(), {
      method: 'GET',
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
      }
   }).then(function (response) {
      return response.json();
   }).then(function (data) {

      var movesetRaw = data.moves.filter(function (x) {
         return x.version_group_details.some(function (y) {
            return y.version_group.name == "ultra-sun-ultra-moon"
         });
      });

      movesetRaw.forEach(x => {
         var usumVer = x.version_group_details.filter(function (y) {
            return y.version_group.name == "ultra-sun-ultra-moon"
         });

         x.version_group_details = usumVer;
      });

      var levelup = movesetRaw.filter(x => {
         return x.version_group_details.filter(y => y.move_learn_method.name == "level-up").length > 0;
      });

      var machine = movesetRaw.filter(x => {
         return x.version_group_details.filter(y => y.move_learn_method.name == "machine").length > 0;
      });

      var levelupMoveSet = formatLevelup(levelup);
      var machineMoveSet = formatMachine(machine);
      var typing = formatTypes(data.types);

      storePokemon(levelupMoveSet, machineMoveSet, typing, pokemonName);
   });
}

function formatTypes(types) {
   var typing = [];
   
   types = types.sort((x, y) => {
      return x.slot < y.slot ? -1 : x.slot > y.slot ? 1 : 0;
   });

   for(var type of types){
      typing.push(type.type.name);
   }

   return typing;
}

function formatLevelup(levelupSet) {
   const moveset = [];

   for (var moveRaw of levelupSet) {
      var levelData = moveRaw.version_group_details.filter(x => x.move_learn_method.name == 'level-up')[0];

      var move = {
         "name": moveRaw.move.name,
         "level": levelData.level_learned_at
      }

      moveset.push(move);
   }

   return moveset
}

function formatMachine(levelupSet) {
   const moveset = [];

   levelupSet = levelupSet.sort((x, y) => {
      var textX = x.move.name.toUpperCase();
      var textY = y.move.name.toUpperCase();

      return (textX < textY) ? -1 : (textX > textY) ? 1 : 0;
   });

   for (var moveRaw of levelupSet) {
      var move = {
         "name": moveRaw.move.name
      }

      moveset.push(move);
   }

   return moveset;
}

function storePokemon(levelup, machine, typing, speciesName) {
   // const ref = firebase.firestore().collection('PokemonLookup').doc();

   // ref.set(returnNewPokemon(levelup, machine, typing, speciesName))
   //    .then((doc) => {
   //       console.log(doc);
   //    });
   console.log(returnNewPokemon(levelup, machine, typing, speciesName));
}

// function getPokemon(key) {
//    firebase.firestore().collection('PokemonLookup').doc(key).get().then(function (doc) {
//       console.log(doc.id);
//       console.log(doc.data());
//    });
// }


function addNewMoves() {
   var moveset = [];

   moveset.push(formatMove("Leaf Shield", 8));
   // moveset.push(formatMove("Squish", 5));
   // moveset.push(formatMove("Spirit Rush", 15));
   // moveset.push(formatMove("Goodnight", 15));
   // moveset.push(formatMove("Haunting", 25));
   // moveset.push(formatMove("Doom Cry", 35));
   // moveset.push(formatMove("Rapid River", 40));
   // // moveset.push(formatMove("Squish", 1));
   // moveset.push(formatMove("Pixie Dust", 27));
   // moveset.push(formatMove("Mystic Wave", 30));
   // moveset.push(formatMove("Spirit Rush", 18));
   // moveset.push(formatMove("Light Burst", 20));
   // moveset.push(formatMove("Cheap Shot", 30));

   return moveset;
}

function formatMove(name, level) {
   return {
      "name": name,
      "level": level
   }
}


var listNames = [
   "Snubbull"
]


function returnNewPokemon(levelup, machine, typing, speciesName) {
   levelup = levelup.concat(addNewMoves());

   var pokemon = {
      "speciesName": speciesName,
      "imageUrl": "https://img.pokemondb.net/artwork/" + speciesName.toLowerCase() + ".jpg",
      "region": "Johto",
      "types": typing,
      "abilityBasic": [
         "Intimidate", "Run Away"
      ],
      "abilityAdvanced": [
         "Rattled", "Quick Feet"
      ],
      "abilityHigh": [
         "Strong Jaw"
      ],
      "movesLevelup": levelup,
      "movesMachine": machine
   };

   return pokemon;
}

export default RemoveLater;