import React, { Component, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import SessionEvent from './session-event';
import Split from './Split/split';
import Ooc from './Ooc/ooc';

export default function SessionEventContainer(props) {
   const [event, setEvent] = React.useState(props.sessionEvent);
   const [settings, setSettings] = React.useState(props.initialSettings);

   useEffect(() => {
      setSettings(props.initialSettings);
   }, [props.initialSettings]);

   function RenderOoc() {
      var oocType = settings.ooc;

      if (oocType == 'split') {
         return SplitOoc();
      }

      if (oocType == 'inline') {
         return InlineOoc();
      }

      if (oocType == 'off') {
         return OffOoc();
      }
   }

   function SplitOoc() {
      return <Grid container>
         <Grid item lg={settings.ooc == 'split' ? 9 : 12}>
            {event.SplitEvents ?  RenderSplit() : RenderSingleConvo()}
         </Grid>
         {event.OocEvents ?
            <Grid item lg={3} style={event.OocEvents.length > 0 ? { borderLeft: '#c8ad7f solid' } : {}}>
               {
                  event.OocEvents.map(oocEvent =>
                     <Ooc sessionEvent={oocEvent} initialSettings={settings} key={oocEvent.Id} />
                  )}
            </Grid>
            : ''
         }
      </Grid>;
   }

   function InlineOoc() {
      return <Grid container>

         <Grid item lg={12}>
            {event.SplitEvents ? RenderSplit() : RenderSingleConvo()}
         </Grid>

         {event.OocEvents ?
            event.OocEvents.map(oocEvent =>
               <Grid item lg={12}>
                  <Ooc sessionEvent={oocEvent} initialSettings={settings} key={oocEvent.Id} />
               </Grid>
            )
            : ''
         }
      </Grid>;
   }

   function OffOoc() {
      return <Grid container>
         {event.SplitEvents ? RenderSplit() : RenderSingleConvo()}
      </Grid>;
   }

   function RenderSplit() {
      return (
         <Split sessionEvent={event} initialSettings={settings} key={event.Id} /> 
      );
   }

   function RenderSingleConvo() {
      return (
         <SessionEvent sessionEvent={event} settings={settings} key={event.Id} />
      );
   }

   return (
      RenderOoc()
   );
}