import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class CharacterProfile extends Component {
   constructor(props) {
      super(props);

      const urlParams = this.props.match;
      const profile = null;
      this.state = { profile: profile, characterId: urlParams.params.id };
   }

   callAPI() {
      fetch("https://api.soaringsomeone.me/kaiyou/profiles/players/" + this.state.characterId)
         .then(res => res.json())
         .then((data) => {

            data.edges = data.edges.filter(edge => {
               return edge.level <= data.level;
            });

            data.miscFeatures = data.miscFeatures.filter(miscFeature => {
               return miscFeature.level <= data.level;
            });

            data.classes = data.classes.filter(playerClass => {
               return playerClass.level <= data.level;
            });

            for (const playerClass of data.classes) {
               playerClass.features = playerClass.features.filter(feature => {
                  return feature.level <= data.level;
               })
            }

            // Sort alphabetically
            data.edges = data.edges.sort((a, b) => {
               return (a.level < b.level) ? -1 : (a.level > b.level) ? 1 : 0;
            });

            this.setState({ profile: data });
         });
   }

   componentDidMount() {
      this.callAPI();
   }

   render() {
      if (!this.state.profile) {
         return (
            <Container style={{ paddingLeft: '15%', paddingRight: '15%' }}>
            </Container>
         );
      }

      const containsMiscFeatures = this.state.profile.miscFeatures.length;
      const featureEdgeSizeLg = containsMiscFeatures ? 4 : 6;

      // Add level, stats formatted correctly
      return (
         <Grid container style={{ paddingLeft: '15%', paddingRight: '15%', paddingTop: '50px', paddingBottom: '50px', width: 'calc(100% - 48px)', whiteSpace: 'pre-wrap' }} spacing={5}>
            <Grid item lg={4} xs={12}>
               <img src={require('../../assets/Profile/' + this.state.profile.imageProfile)} style={{ width: '100%', outline: 'solid' }}></img>
            </Grid>
            <Grid item lg={8} xs={12}>
               <Typography component="div" variant='h3'>
                  {this.state.profile.name}
               </Typography>
               <Typography component="div" style={{ paddingBottom: '30px' }} variant='subtitle2'>
                  Player: {this.state.profile.player}
               </Typography>
               <Typography component="div">
                  Age: {this.state.profile.age}
               </Typography>
               <Typography component="div">
                  Height: {this.state.profile.height}
               </Typography>
               <Typography component="div">
                  Weight: {this.state.profile.weight}
               </Typography>
               <Typography component="div">
                  Level: {this.state.profile.level}
               </Typography>
               <Typography component="div" variant='subtitle2' style={{ paddingTop: '20px', paddingBottom: '30px' }}>
                  "{this.state.profile.bio}"
               </Typography>
            </Grid>

            <Grid item lg={featureEdgeSizeLg}>
               <Typography component="div" variant='h4' style={{ paddingBottom: '20px' }}>
                  Classes
               </Typography>
               {this.state.profile.classes.map(playerClass =>
                  <div style={{ paddingBottom: '40px' }}>
                     <Typography component="div" variant='h5'>
                        {playerClass.class}
                     </Typography>
                     {playerClass.effect ?
                        <Typography component="div" variant='body2' style={{ paddingBottom: '20px' }}>
                           {playerClass.effect}
                        </Typography> :
                        ''}
                     {playerClass.features.map(feature =>
                        <Typography component="div" variant='body2' style={{ paddingBottom: '20px' }}>
                           <b>{feature.name}</b> - {feature.effect}
                        </Typography>
                     )}
                  </div>
               )}
            </Grid>

            {containsMiscFeatures ?
               <Grid item lg={featureEdgeSizeLg}>
                  <Typography component="div" variant='h4' style={{ paddingBottom: '20px' }}>
                     Other Features
               </Typography>
                  {this.state.profile.miscFeatures.map(feature =>
                     <Typography component="div" variant='body2' style={{ paddingBottom: '20px' }}>
                        <b>{feature.name}</b> - {feature.effect}
                     </Typography>
                  )}
               </Grid>
               : ''
            }
            <Grid item lg={featureEdgeSizeLg}>
               <Typography component="div" variant='h4' style={{ paddingBottom: '20px' }}>
                  Edges
               </Typography>
               {this.state.profile.edges.map(edge =>
                  <Typography component="div" variant='body2' style={{ paddingBottom: '20px' }}>
                     <b>{edge.name}</b> - {edge.effect}
                  </Typography>
               )}
            </Grid>
         </Grid>
      );
   }
}

export default CharacterProfile;