import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Flare from '@material-ui/icons/Flare';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SessionEvent from '../../SessionEvent/session-event';
import { Typography } from '@material-ui/core';
import { FormatItalicOutlined } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

export default function Battle(props) {
   const [event, setEvent] = React.useState(props.sessionEvent);
   const [open, setOpen] = React.useState(false);
   const [settings, setSettings] = React.useState(props.initialSettings);
   const [expanded, setExpanded] = React.useState(false);

   const imageUri = require.context('../../../assets/Sprites', true);
   const handleClick = () => {
      setOpen(!open);
   };

   const handleExpandClick = () => {
      setExpanded(!expanded);
   };

   function FormatBattleTitle() {
      var protagNames = event.Characters.map(character => character.name);
      var opponentNames = event.Opponents.map(character => character.name);

      var players = protagNames.join(', ');
      var opponents = opponentNames.join(', ');

      return players + ' VS ' + opponents;
   }

   // function FormatBattleImages() {
   //    var protagNames = event.Characters.map(character => `${character.sprite}`);
   //    var opponentNames = event.Opponents.map(character => character.sprite);

   //    let protagImages;
   //    for(var i = 0; i < protagNames.length; i++)
   //    {
   //       let img = images('./'+)
   //       protagImages += <img src=
   //    }

   //    return players + ' VS ' + opponents;
   // }

   return (
      <Container style={{ marginTop: '10px', marginBottom: '10px' }}>
         <Box textAlign="center" style={{ background:'#E8E8E8' }}>
            <Typography>
               {FormatBattleTitle()}
               <IconButton onClick={handleExpandClick} justify="flex-end">
                  <ExpandMoreIcon />
               </IconButton>
            </Typography>
         </Box>
         <Collapse in={expanded} timeout="auto" unmountOnExit style={{ marginTop: '10px' }}>
            {
               event.Events.map(battleEvent =>
                  <SessionEvent sessionEvent={battleEvent} settings={settings} key={battleEvent.Id} oocEvents={battleEvent.OocEvents ? battleEvent.childOoc : []} />
               )}
         </Collapse>
      </Container>
   );
}