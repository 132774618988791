import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Container } from '@material-ui/core';

export default function ImageInsert({ sessionEvent }) {
   const [event, setEvent] = React.useState(sessionEvent);

   return (
      <Container align='center' style={{ marginBottom: '40px', marginTop: '20px' }}>
         <img style={{maxHeight: '500px', width: 'auto'}}
            src={require('../../../assets/SessionImages/' + event.Url + '.png')}
         />
         <Typography align='center' style={{ width: "100%", alignItems: "center", paddingTop: '10px' }} variant='caption' display='block'>{event.Caption}</Typography>
      </Container>
   );
}