import React, { Component } from 'react';
import NpcProfileCard from './npc-profile-card'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class NpcProfilePage extends Component {
   constructor(props) {
      super(props);
      this.state = { profiles: [] };
   }

   callAPI() {
      const profiles = [];

      fetch("https://api.soaringsomeone.me/kaiyou/profiles/npc")
         .then(res => res.json())
         .then((data) => {
            data.forEach(profile => {
               if (!profiles[profile.type]) {
                  profiles[profile.type] = [];
               }

               profiles[profile.type].push(profile);
            });

            this.setState({ profiles: profiles });
         });
   }

   componentDidMount() {
      this.callAPI();
   }

   render() {
      if (!this.state.profiles) {
         return (
            <Grid>
            </Grid>
         )
      }
      return (
         <Grid container style={{ paddingLeft: '15%', paddingRight: '15%', paddingTop: '50px', paddingBottom: '50px' }}>
            {
               Object.keys(this.state.profiles).map(profileGroup => {
                  return (
                     <Grid item lg={12} style={{ paddingBottom: '40px' }} >
                        <Typography variant='h3' style={{ paddingBottom: '20px' }}>
                           {profileGroup}
                        </Typography>
                        <Grid container>
                           {this.state.profiles[profileGroup].map(profile => {
                              return (
                                 <Grid item lg={3} xs={12}>
                                    < NpcProfileCard profileData={profile} />
                                 </Grid>
                              )
                           })}
                        </Grid>
                     </Grid>
                  )
               }
               )
            }
         </Grid>
      );
   }
}


export default NpcProfilePage;
