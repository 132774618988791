import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextImagePreview from '../TextImagePreview/text-image-preview';

export default function Dialogue({ sessionEvent }) {
   const [event, setEvent] = React.useState(sessionEvent);

   function FormatDialogue(textArray) {
      var newTextArray = [];

      for (var i = 0; i < textArray.length; i++) {
         var currentText = textArray[i];

         if (!currentText.match(/[\.\?\"]$/)) {
            currentText += '.';
         }

         if (currentText.match(/^\"/) &&
            i != 0 &&
            textArray[i - 1].match(/\"$/)) {
            currentText = currentText.substring(1);
         }

         if (currentText.match(/\"$/) &&
            i != textArray.length - 1 &&
            textArray[i + 1].match(/^\"/)) {
            currentText = currentText.slice(0, -1);
         }

         newTextArray.push(currentText);
      }

      var fullText = newTextArray.join(' ');
      return (fullText);
   }

   return (
      <div className={event.Character + " events dialogue"}>
         <div>
            <div className="characterName">{event.Character}: </div>
            {event.Image ?
               <TextImagePreview sessionText={FormatDialogue(event.Action.Text)} sessionEvent={event} />
               :
               <Typography> {FormatDialogue(event.Action.Text)} </Typography>
            }
         </div>
      </div>
   );
}