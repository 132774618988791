import React, { Component, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import SessionEventContainer from '../SessionEvent/session-event-container';
import './event-viewer.css';

export default function EventViewer(props) {
  const [settings, setSettings] = React.useState(props.initialSettings);
  const [url, setUrl] = React.useState(props.urlParams);
  const [sessionEvents, setSessionEvents] = React.useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const retrievedSessionEvents = [];

      const response = await fetch("https://api.soaringsomeone.me/kaiyou/session/" + url.params.sessionId);
      const data = await response.json();

      data.events.sort((a, b) => {
        return (a.Id < b.Id) ? -1 : (a.Id > b.Id) ? 1 : 0
      });

      for (var i = 0; i < data.events.length; i++) {
        var event = data.events[i];

        if (event.OocEvents) {
          event.OocEvents.forEach(element => {
            element.Character = element.Player;
          });

          event.OocEvents.sort((a, b) => {
            return (a.Id < b.Id) ? -1 : (a.Id > b.Id) ? 1 : 0
          });
        }

        if(event.SplitEvents){
          event.SplitEvents.forEach(array =>
            {
              array.sort((a, b) => {
                return (a.Id < b.Id) ? -1 : (a.Id > b.Id) ? 1 : 0
            })
          });
        }

        if(event.Events){
          event.Events.sort((a, b) => {
            return (a.Id < b.Id) ? -1 : (a.Id > b.Id) ? 1 : 0
          });
        }

        retrievedSessionEvents.push(event);
      }

      setSessionEvents(retrievedSessionEvents);
    }

    fetchData();
  }, [props.urlParams]);

  useEffect(() => {
    setSettings(props.initialSettings);
  }, [props.initialSettings]);

  return (
    <Container style={{ padding: '0px' }} maxWidth={false}>
      <CssBaseline />
      <Typography component="div" style={{ height: '100vh', paddingBottom: '56px', overflowY: 'scroll', overflowX: 'hidden', paddingLeft: '15%', paddingRight: '15%' }}>
        {
          sessionEvents.map(sessionEvent =>
            <SessionEventContainer sessionEvent={sessionEvent} initialSettings={settings} key={sessionEvent.Id} />
          )}
      </Typography>
    </Container>
  );
}