import React from 'react';

export default function DiceRoll({sessionEvent}) {
   const [event, setEvent] = React.useState(sessionEvent);

   return (
      <div className={event.Character + " events diceRoll"}>
         <div>
            <div className={"characterName"}>{event.Character} rolling {event.Action.Text ? event.Action.Text + ', ' : ''} {event.Action.Formula}: </div>
            <span> ({event.Action.Dice.join('+')}) {event.Action.ModifierType ? event.Action.ModifierType + ' ' + event.Action.Modifier : ''} = {event.Action.Total}
            </span>
         </div>
      </div>
   );
}