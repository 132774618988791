import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Container } from '@material-ui/core';
import KaiyouImage from '../../assets/Kaiyou.png';

class Home extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container style={{ paddingTop: '50px', paddingBottom: '50px' }}>
        {/* <Container style={{ paddingBottom: '50px' }}>
          <Typography variant="h3">
            <Box textAlign="center">
              <img src={KaiyouImage} alt="Kaiyou" />
            </Box>
            <Box textAlign="center">
              Welcome to Kaiyou
            </Box>
          </Typography>
        </Container> */}

        <Typography component="div" variant='h6'>
          12/17/2020 - v1.2.2
        </Typography>
        <Typography component="div" variant='body2' style={{ whiteSpace: 'pre-wrap', marginBottom: '30px' }}>
          Final feature added to session viewer for v1.2. Contains image preview on hover capabilities, contains image insert functionality. 
        </Typography>

        <Typography component="div" variant='h6'>
          12/12/2020 - v1.2.1
        </Typography>
        <Typography component="div" variant='body2' style={{ whiteSpace: 'pre-wrap', marginBottom: '30px' }}>
          NPC profiles up. Added Marina, Chad, Sean, and Professor Cedar.
        </Typography>

        <Typography component="div" variant='h6'>
          12/10/2020 - v1.2.0
        </Typography>
        <Typography component="div" variant='body2' style={{ whiteSpace: 'pre-wrap', marginBottom: '30px' }}>
          Redone session viewer
          <br/>
          Groomed out Session 1
        </Typography>

        <Typography component="div" variant='h6'>
          11/12/2020 - v1.1.1
        </Typography>
        <Typography component="div" variant='body2' style={{ whiteSpace: 'pre-wrap', marginBottom: '30px' }}>
          Initial search functionality in lookup.
        </Typography>

        <Typography component="div" variant='h6'>
          11/11/2020 - v1.1.0
        </Typography>
        <Typography component="div" variant='body2' style={{ whiteSpace: 'pre-wrap', marginBottom: '30px' }}>
          Fixed menu icon to always be top left
          <br />
          Now mobile friendly.
        </Typography>

        <Typography component="div" variant='h6'>
          11/1/2020 - v1.0.0
        </Typography>
        <Typography component="div" variant='body2' style={{ whiteSpace: 'pre-wrap' }}>
          Initial deployment of tabletop viewer. Base version of session viewer, lookups, and player character profiles added.
          <br />
          <br />
          Sessions 1-2 added.
          <br />
          Initial 5 Player Characters added.
          <br />
          Current 120 Lookup Pokemon added.
        </Typography>
      </Container>
    );
  }
}

export default Home;
