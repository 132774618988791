import React, { Component } from 'react';
import SimpleBottomNavigation from '../BottomNav/bottom-nav'
import EventViewer from '../EventViewer/event-viewer'
import SettingsDialogue from '../SettingsDialog/settings-dialogue';


export default function SessionViewer({ urlParams }) {
  const initialSettings = {
    coloredText: false,
    ooc: 'split',
    whispers: 'split',
    pagination: 'off',
    collapseBattle: 33,
    spoilBattle: 33
  }

  const [settings, setSettings] = React.useState(initialSettings);

  function handleSettingsChanges(newSettings) {
    setSettings(newSettings);
  }

  return (
    <div style={{ width: '100%' }}>
      <SettingsDialogue initialSettings={settings} onChange={handleSettingsChanges} />
      <EventViewer urlParams={urlParams} initialSettings={settings} />
      <SimpleBottomNavigation urlParams={urlParams} />
    </div>
  );
}