import React, { Component } from 'react';
import CharacterProfileCard from './character-profile-card'
import Grid from '@material-ui/core/Grid';

class CharacterProfilePage extends Component {
   constructor(props) {
      super(props);
      this.state = { profiles: [] };
   }

   callAPI() {
      const profiles = [];

      fetch("https://api.soaringsomeone.me/kaiyou/profiles/players")
         .then(res => res.json())
         .then((data) => {
            data.forEach(profile => {
               profiles.push(profile);
            });

            this.setState({ profiles: profiles });
         });
   }

   componentDidMount() {
      this.callAPI();
   }

   render() {
      return (
         <Grid container style={{ paddingLeft: '15%', paddingRight: '15%', paddingTop: '50px', paddingBottom: '50px' }}>
            {
               this.state.profiles.map(profile =>
                  <Grid item lg={3} xs={12} style={{ paddingBottom: '40px' }} key={profile._id}>
                     <CharacterProfileCard profileData={profile} />
                  </Grid>
               )}
         </Grid>
      );
   }
}

export default CharacterProfilePage;
