import React, { Component, useEffect } from 'react';
import DiceRoll from './DiceRoll/dice-roll';
import Whisper from './Whisper/whisper';
import Dialogue from './Dialogue/dialogue';
import Emote from './Emote/emote';
import Ooc from './Ooc/ooc';
import Battle from './Battle/battle';
import Split from './Split/split';
import ImageInsert from './ImageInsert/image-insert';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import './session-event.css';

export default function SessionEvent(props) {
   const [sessionEvent, setSessionEvent] = React.useState(props.sessionEvent);
   const [settings, setSettings] = React.useState(props.settings);

   useEffect(() => {
      setSettings(props.settings);
   }, [props.settings]);

   function RenderEventType() {
      var type = sessionEvent.ActionType;

      if (type == 'Dialogue') {
         return <Dialogue sessionEvent={sessionEvent} />;
      }

      if (type == 'Emote') {
         return <Emote sessionEvent={sessionEvent} />
      }

      if (type == 'DiceRoll') {
         return <DiceRoll sessionEvent={sessionEvent} />;
      }

      if (type == 'Whisper') {
         return <Whisper sessionEvent={sessionEvent} />;
      }

      if (type == 'Battle') {
         return <Battle sessionEvent={sessionEvent} initialSettings={settings} />;
      }

      if (type == 'Split') {
         return <Split sessionEvent={sessionEvent} initialSettings={settings} />;
      }

      if (type == 'ImageInsert') {
         return <ImageInsert sessionEvent={sessionEvent} />;
      }
   }
   
   return (
      RenderEventType()
   );
}