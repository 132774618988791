import React, { Component } from 'react';
import SessionViewer from '../SessionViewer/session-viewer';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { apiResponse: "" };
  }

  render() {
    return (
      <div style={{width:'100%'}}>
        <SessionViewer urlParams={this.props.match}/>
      </div>
    );
  }
}

export default App;