import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class NpcProfile extends Component {
   constructor(props) {
      super(props);

      const urlParams = this.props.match;
      const profile = null;
      this.state = { profile: profile, npcId: urlParams.params.id };
   }

   callAPI() {
      fetch("https://api.soaringsomeone.me/kaiyou/profiles/npc/" + this.state.npcId)
         .then(res => res.json())
         .then((data) => {
            this.setState({ profile: data });
            console.log(this.state.profile);
         });
   }

   componentDidMount() {
      this.callAPI();
   }

   render() {
      if (!this.state.profile) {
         return (
            <Container style={{ paddingLeft: '15%', paddingRight: '15%' }}>
            </Container>
         );
      }

      // Add level, stats formatted correctly
      return (
         <Grid container style={{ paddingLeft: '15%', paddingRight: '15%', paddingTop: '50px', paddingBottom: '50px', width: 'calc(100% - 48px)', whiteSpace: 'pre-wrap' }} spacing={5}>
            <Grid item lg={4} xs={12}>
               <img src={require('../../assets/Profile/' + this.state.profile.imageProfile)} style={{ width: '100%', outline: 'solid' }}></img>
            </Grid>
            <Grid item lg={8} xs={12}>
               <Typography component="div" variant='h3'>
                  {this.state.profile.name}
               </Typography>
               <Typography component="div">
                  Age: {this.state.profile.age}
               </Typography>
               <Typography component="div">
                  Height: {this.state.profile.height}
               </Typography>
               <Typography component="div">
                  Weight: {this.state.profile.weight}
               </Typography>
               <Typography component="div">
                  Known Classes: {this.state.profile.classes.join(", ")}
               </Typography>
               <Typography component="div" variant='subtitle2' style={{ paddingTop: '20px', paddingBottom: '30px' }}>
                  "{this.state.profile.bio}"
               </Typography>
            </Grid>

            <Grid item>
               <Typography component="div" variant='h4' style={{ paddingBottom: '20px' }}>
                  Known Pokemon
               </Typography>
               {this.state.profile.knownTeam.map(pokemon =>
                  <div style={{ paddingBottom: '40px' }}>
                     <Typography component="div" variant='h5'>
                        {pokemon}
                     </Typography>
                  </div>
               )}
            </Grid>
         </Grid>
      );
   }
}

export default NpcProfile;