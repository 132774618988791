import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextImagePreview from '../TextImagePreview/text-image-preview';

export default function Emote({ sessionEvent }) {
   const [event, setEvent] = React.useState(sessionEvent);

   return (
      <div className={event.Character + " events emote"}>
         <Typography>
            {event.Image ? 
               <TextImagePreview sessionText={event.Character + ' ' + event.Action.Text} sessionEvent={event} />
               :
               event.Character + ' ' + event.Action.Text
            }
         </Typography>
      </div>
   );
}