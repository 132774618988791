import React from 'react';

export default function Whisper({sessionEvent}) {
   const [event, setEvent] = React.useState(sessionEvent);

   return (
      <div className={event.Character + " events whisper"}>
      <div>
         <div className={"characterName"}>{event.Character} To {event.Action.Target}: </div>{event.Action.Text}
      </div>
   </div>
   );
}