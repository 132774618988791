import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

class NpcProfileCard extends Component {
  constructor(props) {
    super(props);

    const profileData = props.profileData;
    this.state = { profile: profileData };
  }

  render() {
    return (
      <Card style={{ width: "250px", height: "625px" }}>
        <CardActionArea component={Link} to={'/kaiyou/npcProfiles/' + this.state.profile._id}>
          <CardMedia
            component="img"
            alt={this.state.profile.name}
            height="500"
            image={require('../../assets/Profile/' + this.state.profile.imageCard)}
            title={this.state.profile.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {this.state.profile.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {this.state.profile.introduction}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

export default NpcProfileCard;
