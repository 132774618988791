import React, { Component, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import SessionEvent from '../session-event';

export default function Split(props) {
   const [event, setEvent] = React.useState(props.sessionEvent);
   const [settings, setSettings] = React.useState(props.initialSettings);

   function RenderSplitConvo() {
      var columns = event.SplitEvents.length;

      var columnSize = Math.trunc(12 / columns);

      return (
         <Grid container style={{ paddingTop: '25px', paddingBottom: '25px' }} spacing={1}>
            {event.SplitEvents.map(function (splitArray, i) {
               return (
                  <Grid item lg={columnSize} key={i}>
                     {splitArray.map(splitEvent =>
                        <SessionEvent sessionEvent={splitEvent} settings={settings} key={splitEvent.Id} />
                     )}
                  </Grid>
               )
            })}
         </Grid>
      );
   }

   return (
      RenderSplitConvo()
   );
}