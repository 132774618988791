import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import './text-image-preview.css';

export default function TextImagePreview({ sessionText, sessionEvent }) {
   const [text, setText] = React.useState(sessionText);
   const [event, setEvent] = React.useState(sessionEvent);

   const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
         backgroundColor: '#f5f5f9',
         color: 'rgba(0, 0, 0, 0.87)',
         maxWidth: 220,
         fontSize: theme.typography.pxToRem(12),
         border: '1px solid #dadde9',
      },
   }))(Tooltip);

   function HighlightText() {
      var imageText = event.ImageText ? event.ImageText : event.Image;
      var stringIndex = text.toLowerCase().indexOf(imageText.toLowerCase());

      return (
         <Typography>
            {text.substring(0, stringIndex)}

            <HtmlTooltip placement="top"
               title={
                  <React.Fragment>
                     <img width='200px' height='200px' src={require('../../../assets/Pokemon/' + sessionEvent.Image + '.png')}></img>
                  </React.Fragment>
               }
            >
               <span className='imagePreviewText'>
                  {imageText}
               </span>
            </HtmlTooltip>
            {text.substring(stringIndex + imageText.length)}
         </Typography>
      )
   }

   return (
      HighlightText()
   );
}