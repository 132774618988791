import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import CharacterProfilePage from './components/CharacterProfile/character-profile-page';
import CharacterProfile from './components/CharacterProfile/character-profile';
import NpcProfilePage from './components/NpcProfile/npc-profile-page';
import NpcProfile from './components/NpcProfile/npc-profile';
import PokemonLookup from './components/PokemonLookup/pokemon-lookup';
import PokemonLookupProfile from './components/PokemonLookup/pokemon-lookup-profile';
import KaiyouMenu from './components/KaiyouMenu/kaiyou-menu';
import Home from './components/Home/home';
import RemoveLater from './components/RemoveLater/test';
import RemoveLater2 from './components/RemoveLater/testCapx';
import * as serviceWorker from './serviceWorker';
// import SettingsDialog from './components/SettingsDialog/settings-dialogue';

ReactDOM.render(
  // <React.StrictMode>
    <div style={{display:'flex'}}>
      <Router>
        <KaiyouMenu/>
        <Switch>
          <Route path='/kaiyou/formatPokemon/' component={RemoveLater}></Route>
          <Route path='/kaiyou/formatPokemonCapx/' component={RemoveLater2}></Route>
          <Route path='/kaiyou/profiles/:id' component={CharacterProfile}></Route>
          <Route path='/kaiyou/profiles/' component={CharacterProfilePage}></Route>
          <Route path='/kaiyou/npcProfiles/:id' component={NpcProfile}></Route>
          <Route path='/kaiyou/npcProfiles/' component={NpcProfilePage}></Route>
          <Route path='/kaiyou/lookup/:id' component={PokemonLookupProfile}></Route>
          <Route path='/kaiyou/lookup/' component={PokemonLookup}></Route>
          <Route path='/kaiyou/session/:sessionId/' component={App}></Route>
          <Route path='/' component={Home}></Route>
        </Switch>
        {/* <SettingsDialog/> */}
      </Router>
    </div>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
