import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';

export default function KaiyouMenu() {
   const [anchorEl, setAnchorEl] = React.useState(null);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   return (
      <div style={{position: "fixed"}}>
         <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <MenuIcon />
         </IconButton >
         <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
         >
            <MenuItem onClick={handleClose} component={Link} to='/'>Home</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/kaiyou/session/1'>Sessions</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/kaiyou/lookup/'>Pokemon Lookup</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/kaiyou/profiles/'>Character Profiles</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to='/kaiyou/npcProfiles/'>NPC Profiles</MenuItem>
         </Menu>
      </div>
   );
}