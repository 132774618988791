import React, { Component } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SkipNext from '@material-ui/icons/SkipNext';
import NavigateNext from '@material-ui/icons/NavigateNext';
import FastRewind from '@material-ui/icons/FastRewind';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import SkipPrevious from '@material-ui/icons/SkipPrevious';
import FastForward from '@material-ui/icons/FastForward';
import './bottom-nav.css';


class SimpleBottomNavigation extends Component {
  constructor(props) {
    super(props);

    const urlParams = props.urlParams;
    this.state = { 
      sessionId: urlParams.params.sessionId,
      anchorEl: null
    };
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  handleClose() {
    this.setState({
      anchorEl: null
    })
  };

  componentDidMount() {
  }

  render() {
    return (
      <BottomNavigation
        onChange={(event, newValue) => { }}
        showLabels
        style={{ width: 'calc(100% - 20px)', position: 'fixed', bottom: 0 }} //Change to 117 when settings comes in.
      >
        <BottomNavigationAction
          label="First"
          icon={<SkipPrevious />}
          href='/kaiyou/session/1'
          disabled={this.state.sessionId == 1} />

        <BottomNavigationAction
          label="Previous Session"
          icon={<FastRewind />}
          href={'/kaiyou/session/' + Math.max(this.state.sessionId - 1, 1)}
          disabled={this.state.sessionId == 1} />

        {/* <BottomNavigationAction label="Back" icon={<NavigateBefore />} /> */}
        <BottomNavigationAction label={"Session " + this.state.sessionId} aria-controls="simple-menu" aria-haspopup="true"/>
        {/* <BottomNavigationAction label="Continue" icon={<NavigateNext />} /> */}
        <BottomNavigationAction label="Next Session" icon={<FastForward />} href={'/kaiyou/session/' + Math.min(this.state.sessionId + 1, 2)} disabled={this.state.sessionId == 2} />
        <BottomNavigationAction label="Most Recent" icon={<SkipNext />} href='/kaiyou/session/2' disabled={this.state.sessionId == 2} />
      </BottomNavigation>
    );
  }


}


export default SimpleBottomNavigation;