import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';
import PokemonLookupProfile from './pokemon-lookup-profile';
import { Container } from '@material-ui/core';

class PokemonLookup extends Component {
  constructor(props) {
    super(props);

    const pokemonList = [];
    this.state = { pokemonList: pokemonList, filteredPokemonList: pokemonList };
    this.filter = this.filter.bind(this);
  }

  callAPI() {
    fetch("https://api.soaringsomeone.me/kaiyou/lookup/")
      .then(res => res.json())
      .then((data) => {
        const pokemonList = [];

        data = data.sort((a, b) => {
          const textA = a.speciesName.toUpperCase();
          const textB = b.speciesName.toUpperCase();

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        for (const pokemon of data) {
          pokemonList.push(pokemon);
        }

        this.setState({ pokemonList: pokemonList, filteredPokemonList: pokemonList  });
      });
  }

  componentDidMount() {
    this.callAPI();
  }

  filter(event){
    var searchTerm = event.target.value;

    var filteredPokemonList = this.state.pokemonList.filter(pokemon =>{
      return pokemon.speciesName.toUpperCase().includes(searchTerm.toUpperCase());
    })

    this.setState({ filteredPokemonList: filteredPokemonList  });
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Container style={{marginTop: '50px', marginBottom: '20px', display: 'flex'}}>
          <TextField id="outlined-basic" label="Search" variant="outlined" onChange={this.filter} style={{marginLeft: 'auto'}}/>
        </Container>
        <div style={{ width: '80%', paddingLeft: "10%" }}>
          <List>
            {
              this.state.filteredPokemonList.map(pokemon =>
                <ListItem button component={Link} to={'/kaiyou/lookup/' + pokemon._id} key={pokemon._id}>
                  <ListItemText primary={pokemon.speciesName}></ListItemText>
                </ListItem>
              )}
          </List>
        </div>
      </div>
    );
  }
}

export default PokemonLookup;
